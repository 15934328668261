<template>
  <div>
    <el-tabs tab-position="top" :stretch="true" type="border-card">
      <el-tab-pane>
        <span slot="label">业务知识</span>
        <el-card class="box-card" style="margin: 20px 30px" shadow="never">
          <div slot="header" class="clearfix">
            <span>操作员</span>
            <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
          </div>
          <el-form ref="form" :model="operatorFrom" label-width="180px" style="width:80%">
            <el-form-item label="温度:">
              <span slot="label">温度:
                <el-tooltip effect="dark" content="模型生成文本的人性化程度，0-1，数值越高则表示自己发挥的内容越多" placement="top">
                  <i style="margin-left: 5px;" class="el-icon-warning"></i>
                </el-tooltip>
              </span>
              <div class="line">
                <el-slider v-model="operatorFrom.temperature" class="slider" :format-tooltip="formatTooltip1"
                  :max="20"></el-slider>
                <span class="text">{{ formatTooltip1(operatorFrom.temperature) }}</span>
              </div>
            </el-form-item>
            <el-form-item label="历史对话轮数:">
              <span slot="label">历史对话轮数:
                <el-tooltip effect="dark" content="参考历史对话的数量，数值越高则生成结果是参考的历史消息越多，但是使用的显存会越多" placement="top">
                  <i style="margin-left: 5px;" class="el-icon-warning"></i>
                </el-tooltip>
              </span>
              <el-input v-model.number="operatorFrom.historyCount" type="number" min="0" max="10"></el-input>
            </el-form-item>
            <el-form-item label="匹配知识条数:">
              <span slot="label">匹配知识条数:
                <el-tooltip effect="dark" content="从知识库中提取的知识点的数量，提取的越多，模型参考的内容则越多，但当无关内容太多的情况下会影响生成结果的准确性，同时也会增加显存的占用"
                  placement="top">
                  <i style="margin-left: 5px;" class="el-icon-warning"></i>
                </el-tooltip>
              </span>
              <el-input v-model.number="operatorFrom.topK" type="number" min="1" max="10" readonly></el-input>
            </el-form-item>
            <el-form-item label="知识匹配分数阀值:">
              <span slot="label">知识匹配分数阀值:
                <el-tooltip effect="dark" content="匹配知识点是的分数最低值，分数越高，匹配度越高，匹配出来的内容可能越少" placement="top">
                  <i style="margin-left: 5px;" class="el-icon-warning"></i>
                </el-tooltip>
              </span>
              <div class="line">
                <el-slider v-model="operatorFrom.scoreThreshold" class="slider"
                  :format-tooltip="formatTooltip"></el-slider>
                <span class="text">{{ formatTooltip(operatorFrom.scoreThreshold) }}</span>
              </div>
            </el-form-item>
            <el-form-item style="display: flex;justify-content: center;align-items: center;">
              <el-button @click.native="resettingFunction(operatorFrom.configType, operatorFrom.contentType)"
                :disabled="fireTrainingLoading">重置</el-button>
              <el-button type="primary" @click.native="saveOperatorModelConfig(operatorFrom.contentType)"
                :disabled="fireTrainingLoading">应用</el-button>
            </el-form-item>
          </el-form>
        </el-card>
        <el-card class="box-card" style="margin: 40px 30px" shadow="never">
          <div slot="header" class="clearfix">
            <span>社会消防培训</span>
          </div>
          <el-form ref="form" :model="fireTrainingFrom" label-width="180px" style="width:80%">
            <el-form-item label="温度:">
              <span slot="label">温度:
                <el-tooltip effect="dark" content="模型生成文本的人性化程度，0-1，数值越高则表示自己发挥的内容越多" placement="top">
                  <i style="margin-left: 5px;" class="el-icon-warning"></i>
                </el-tooltip>
              </span>
              <div class="line">
                <el-slider v-model="fireTrainingFrom.temperature" class="slider" label="44"
                  :format-tooltip="formatTooltip1" :max="20"></el-slider>
                <span class="text">{{ formatTooltip1(fireTrainingFrom.temperature) }}</span>
              </div>
            </el-form-item>
            <el-form-item label="历史对话轮数:">
              <span slot="label">历史对话轮数:
                <el-tooltip effect="dark" content="参考历史对话的数量，数值越高则生成结果是参考的历史消息越多，但是使用的显存会越多" placement="top">
                  <i style="margin-left: 5px;" class="el-icon-warning"></i>
                </el-tooltip>
              </span>
              <el-input v-model.number="fireTrainingFrom.historyCount" type="number" min="0" max="1"
                label="fdfd"></el-input>
            </el-form-item>
            <el-form-item label="匹配知识条数:">
              <span slot="label">匹配知识条数:
                <el-tooltip effect="dark" content="从知识库中提取的知识点的数量，提取的越多，模型参考的内容则越多，但当无关内容太多的情况下会影响生成结果的准确性，同时也会增加显存的占用"
                  placement="top">
                  <i style="margin-left: 5px;" class="el-icon-warning"></i>
                </el-tooltip>
              </span>
              <el-input v-model.number="fireTrainingFrom.topK" type="number" min="1" max="10" readonly></el-input>
            </el-form-item>
            <el-form-item label="知识匹配分数阀值:">
              <span slot="label">知识匹配分数阀值:
                <el-tooltip effect="dark" content="匹配知识点是的分数最低值，分数越高，匹配度越高，匹配出来的内容可能越少" placement="top">
                  <i style="margin-left: 5px;" class="el-icon-warning"></i>
                </el-tooltip>
              </span>
              <div class="line">
                <el-slider v-model="fireTrainingFrom.scoreThreshold" class="slider"
                  :format-tooltip="formatTooltip"></el-slider>
                <span class="text">{{ formatTooltip(fireTrainingFrom.scoreThreshold) }}</span>
              </div>
            </el-form-item>
            <el-form-item style="display: flex;justify-content: center;align-items: center;">
              <el-button @click.native="resettingFunction(fireTrainingFrom.configType, fireTrainingFrom.contentType)"
                :disabled="fireTrainingLoading">重置</el-button>
              <el-button type="primary" @click.native="saveFireTrainingModelConfig(fireTrainingFrom.contentType)"
                :loading="fireTrainingLoading">应用</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label">专业知识</span>
        <el-card class="box-card" style="margin: 20px 30px" shadow="never">
          <div slot="header" class="clearfix">
            <span>操作员</span>
          </div>
          <el-form ref="form" :model="operatorSpecialityFrom" label-width="180px" style="width:80%">
            <el-form-item label="温度:">
              <span slot="label">温度:
                <el-tooltip effect="dark" content="模型生成文本的人性化程度，0-1，数值越高则表示自己发挥的内容越多" placement="top">
                  <i style="margin-left: 5px;" class="el-icon-warning"></i>
                </el-tooltip>
              </span>
              <div class="line">
                <el-slider v-model="operatorSpecialityFrom.temperature" class="slider" :format-tooltip="formatTooltip1"
                  :max="20"></el-slider>
                <span class="text">{{ formatTooltip1(operatorSpecialityFrom.temperature) }}</span>
              </div>
            </el-form-item>
            <el-form-item label="历史对话轮数:">
              <span slot="label">历史对话轮数:
                <el-tooltip effect="dark" content="参考历史对话的数量，数值越高则生成结果是参考的历史消息越多，但是使用的显存会越多" placement="top">
                  <i style="margin-left: 5px;" class="el-icon-warning"></i>
                </el-tooltip>
              </span>
              <el-input v-model.number="operatorSpecialityFrom.historyCount" type="number" min="0" max="10"></el-input>
            </el-form-item>
            <el-form-item label="匹配知识条数:">
              <span slot="label">匹配知识条数:
                <el-tooltip effect="dark" content="从知识库中提取的知识点的数量，提取的越多，模型参考的内容则越多，但当无关内容太多的情况下会影响生成结果的准确性，同时也会增加显存的占用"
                  placement="top">
                  <i style="margin-left: 5px;" class="el-icon-warning"></i>
                </el-tooltip>
              </span>
              <el-input v-model.number="operatorSpecialityFrom.topK" type="number" min="1" max="10"></el-input>
            </el-form-item>
            <el-form-item label="知识匹配分数阀值:">
              <span slot="label">知识匹配分数阀值:
                <el-tooltip effect="dark" content="匹配知识点是的分数最低值，分数越高，匹配度越高，匹配出来的内容可能越少" placement="top">
                  <i style="margin-left: 5px;" class="el-icon-warning"></i>
                </el-tooltip>
              </span>
              <div class="line">
                <el-slider v-model="operatorSpecialityFrom.scoreThreshold" class="slider"
                  :format-tooltip="formatTooltip"></el-slider>
                <span class="text">{{ formatTooltip(operatorSpecialityFrom.scoreThreshold) }}</span>
              </div>
            </el-form-item>
            <el-form-item style="display: flex;justify-content: center;align-items: center;">
              <el-button
                @click.native="resettingFunction(operatorSpecialityFrom.configType, operatorSpecialityFrom.contentType)"
                :disabled="fireTrainingLoading">重置</el-button>
              <el-button type="primary" @click.native="saveOperatorModelConfig(operatorSpecialityFrom.contentType)"
                :disabled="fireTrainingLoading">应用</el-button>
            </el-form-item>
          </el-form>
        </el-card>
        <el-card class="box-card" style="margin: 40px 30px" shadow="never">
          <div slot="header" class="clearfix">
            <span>社会消防培训</span>
            <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
          </div>
          <el-form ref="form" :model="fireTrainingSpecialityFrom" label-width="180px" style="width:80%">
            <el-form-item label="温度:">
              <span slot="label">温度:
                <el-tooltip effect="dark" content="模型生成文本的人性化程度，0-1，数值越高则表示自己发挥的内容越多" placement="top">
                  <i style="margin-left: 5px;" class="el-icon-warning"></i>
                </el-tooltip>
              </span>
              <div class="line">
                <el-slider v-model="fireTrainingSpecialityFrom.temperature" class="slider" label="44"
                  :format-tooltip="formatTooltip1" :max="20"></el-slider>
                <span class="text">{{ formatTooltip1(fireTrainingSpecialityFrom.temperature) }}</span>
              </div>
            </el-form-item>
            <el-form-item label="历史对话轮数:">
              <span slot="label">历史对话轮数:
                <el-tooltip effect="dark" content="参考历史对话的数量，数值越高则生成结果是参考的历史消息越多，但是使用的显存会越多" placement="top">
                  <i style="margin-left: 5px;" class="el-icon-warning"></i>
                </el-tooltip>
              </span>
              <el-input v-model.number="fireTrainingSpecialityFrom.historyCount" type="number" min="0" max="1"
                label="fdfd"></el-input>
            </el-form-item>
            <el-form-item label="匹配知识条数:">
              <span slot="label">匹配知识条数:
                <el-tooltip effect="dark" content="从知识库中提取的知识点的数量，提取的越多，模型参考的内容则越多，但当无关内容太多的情况下会影响生成结果的准确性，同时也会增加显存的占用"
                  placement="top">
                  <i style="margin-left: 5px;" class="el-icon-warning"></i>
                </el-tooltip>
              </span>
              <el-input v-model.number="fireTrainingSpecialityFrom.topK" type="number" min="1" max="10"></el-input>
            </el-form-item>
            <el-form-item label="知识匹配分数阀值:">
              <span slot="label">知识匹配分数阀值:
                <el-tooltip effect="dark" content="匹配知识点是的分数最低值，分数越高，匹配度越高，匹配出来的内容可能越少" placement="top">
                  <i style="margin-left: 5px;" class="el-icon-warning"></i>
                </el-tooltip>
              </span>
              <div class="line">
                <el-slider v-model="fireTrainingSpecialityFrom.scoreThreshold" class="slider"
                  :format-tooltip="formatTooltip"></el-slider>
                <span class="text">{{ formatTooltip(fireTrainingSpecialityFrom.scoreThreshold) }}</span>
              </div>
            </el-form-item>
            <el-form-item style="display: flex;justify-content: center;align-items: center;">
              <el-button
                @click.native="resettingFunction(fireTrainingSpecialityFrom.configType, fireTrainingSpecialityFrom.contentType)"
                :disabled="fireTrainingLoading">重置</el-button>
              <el-button type="primary"
                @click.native="saveFireTrainingModelConfig(fireTrainingSpecialityFrom.contentType)"
                :disabled="fireTrainingLoading">应用</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label">其他</span>
        <el-card class="box-card" style="margin: 20px 30px" shadow="never">
          <el-form ref="form" :model="otherFrom" >
            <el-form-item label="大模型意图分类之后，其他类型是否需要再次使用小模型识别意图:">
              <el-radio v-model="otherFrom.isOtherTrainModel" :label="true">是</el-radio>
              <el-radio v-model="otherFrom.isOtherTrainModel" :label="false">否</el-radio>
            </el-form-item>
            <el-form-item style="display: flex;justify-content: center;align-items: center;">
              <el-button type="primary" @click.native="saveOtherModelConfig"
                :disabled="otherTrainingLoading">应用</el-button>
            </el-form-item>
          </el-form>
        </el-card>
        <el-card class="box-card" style="margin: 20px 30px" shadow="never">
          <el-form ref="form" :model="scriptFrom">
            <el-form-item label="兜底话术:">
              <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="scriptFrom.baseBottomContent">
              </el-input>
            </el-form-item>
            <el-form-item style="display: flex;justify-content: center;align-items: center;">
              <el-button type="primary" @click.native="saveScriptConfig"
                :disabled="scriptLoading">应用</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-tab-pane>
    </el-tabs>


  </div>
</template>
<script>
import util from "../../../util/date";
import { validPhone } from "../../../util/validate";
import {
  getModelConfigInfo,
  saveModelConfig
} from "../../api/api";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import Qs from "qs";
export default {
  components: { Toolbar },
  data() {
    return {
      operatorLoading: false,
      fireTrainingLoading: false,
      otherTrainingLoading: false,
      scriptLoading: false,
      operatorFrom: {
        configId: null,
        configType: 1,
        contentType: 1,
        historyCount: 0,//历史对话轮数
        scoreThreshold: 0,//知识匹配分数阈值
        temperature: 0,//温度
        topK: 0//匹配知识条数
      },
      fireTrainingFrom: {
        configId: null,
        configType: 2,
        contentType: 1,
        historyCount: 0,
        scoreThreshold: 0,
        temperature: 0,
        topK: 0
      },
      operatorSpecialityFrom: {
        configId: null,
        configType: 1,
        contentType: 2,
        historyCount: 0,//历史对话轮数
        scoreThreshold: 0,//知识匹配分数阈值
        temperature: 0,//温度
        topK: 0//匹配知识条数
      },
      fireTrainingSpecialityFrom: {
        configId: null,
        configType: 2,
        contentType: 2,
        historyCount: 0,
        scoreThreshold: 0,
        temperature: 0,
        topK: 0
      },
      otherFrom: {
        isOtherTrainModel: null
      },
      scriptFrom: {
        baseBottomContent: '',
        configId: null,
      }
    }
  },
  methods: {
    resettingFunction(configType, contentType) {
      this.fireTrainingLoading = false;
      let params = {};
      if (configType == 1 && contentType == 1) {
        this.operatorFrom.historyCount = 0;
        this.operatorFrom.scoreThreshold = 0;
        this.operatorFrom.temperature = 0;
        this.operatorFrom.topK = 0;
        params = Object.assign({}, this.operatorFrom);
      } else if (configType == 2 && contentType == 1) {
        this.fireTrainingFrom.historyCount = 0;
        this.fireTrainingFrom.scoreThreshold = 0;
        this.fireTrainingFrom.temperature = 0;
        this.fireTrainingFrom.topK = 0;
        params = Object.assign({}, this.fireTrainingFrom);
      } else if (configType == 1 && contentType == 2) {
        this.operatorSpecialityFrom.historyCount = 0;
        this.operatorSpecialityFrom.scoreThreshold = 0;
        this.operatorSpecialityFrom.temperature = 0;
        this.operatorSpecialityFrom.topK = 0;
        params = Object.assign({}, this.operatorSpecialityFrom);
      } else if (configType == 2 && contentType == 2) {
        this.fireTrainingSpecialityFrom.historyCount = 0;
        this.fireTrainingSpecialityFrom.scoreThreshold = 0;
        this.fireTrainingSpecialityFrom.temperature = 0;
        this.fireTrainingSpecialityFrom.topK = 0;
        params = Object.assign({}, this.fireTrainingSpecialityFrom);
      }
      saveModelConfig(params).then((res) => {
        if (util.isEmt.format(res)) {
          this.fireTrainingLoading = false;
          return;
        }
        if (res.data.success) {
          this.fireTrainingLoading = false;
          this.$message({
            message: res.data.message,
            type: 'success'
          });
          // this.getDataPermissions();
        }
        else {
          this.fireTrainingLoading = false;
          this.$message({
            message: res.data.message,
            type: 'error'
          });
        }
      });
    },
    saveOperatorModelConfig(contentType) {
      this.$confirm('确认应用吗？', '提示', {}).then(() => {
        this.fireTrainingLoading = true;
        let params = {};
        if (contentType == 1) {
          var temperatureDecimal = this.formatTooltip1(this.operatorFrom.temperature);
          var scoreThresholdDecimal = this.formatTooltip(this.operatorFrom.scoreThreshold);
          params = Object.assign({}, this.operatorFrom);
          params.temperature = temperatureDecimal;
          params.scoreThreshold = scoreThresholdDecimal;
        } else {
          var temperatureDecimal = this.formatTooltip1(this.operatorSpecialityFrom.temperature);
          var scoreThresholdDecimal = this.formatTooltip(this.operatorSpecialityFrom.scoreThreshold);
          params = Object.assign({}, this.operatorSpecialityFrom);
          params.temperature = temperatureDecimal;
          params.scoreThreshold = scoreThresholdDecimal;
        }
        saveModelConfig(params).then((res) => {
          if (util.isEmt.format(res)) {
            this.fireTrainingLoading = false;
            return;
          }
          if (res.data.success) {
            this.fireTrainingLoading = false;
            this.$message({
              message: res.data.message,
              type: 'success'
            });
            // this.getDataPermissions();
          }
          else {
            this.operatorLoading = false;
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          }
        });
      });
    },
    saveFireTrainingModelConfig(contentType) {
      this.$confirm('确认应用吗？', '提示', {}).then(() => {
        this.fireTrainingLoading = true;
        let params = {};
        if (contentType == 1) {
          var temperatureDecimal = this.formatTooltip1(this.fireTrainingFrom.temperature);
          var scoreThresholdDecimal = this.formatTooltip(this.fireTrainingFrom.scoreThreshold);
          params = Object.assign({}, this.fireTrainingFrom);
          params.temperature = temperatureDecimal;
          params.scoreThreshold = scoreThresholdDecimal;
        } else {
          var temperatureDecimal = this.formatTooltip1(this.fireTrainingSpecialityFrom.temperature);
          var scoreThresholdDecimal = this.formatTooltip(this.fireTrainingSpecialityFrom.scoreThreshold);
          params = Object.assign({}, this.fireTrainingSpecialityFrom);
          params.temperature = temperatureDecimal;
          params.scoreThreshold = scoreThresholdDecimal;
        }

        saveModelConfig(params).then((res) => {
          if (util.isEmt.format(res)) {
            this.fireTrainingLoading = false;
            return;
          }
          if (res.data.success) {
            this.fireTrainingLoading = false;
            this.$message({
              message: res.data.message,
              type: 'success'
            });
            // this.getDataPermissions();
          }
          else {
            this.fireTrainingLoading = false;
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          }
        });
      });
    },
    saveOtherModelConfig() {
      this.$confirm('确认应用吗？', '提示', {}).then(() => {
        this.otherTrainingLoading = true;
        let params = {
          contentType: 3,
          isOtherTrainModel: this.otherFrom.isOtherTrainModel
        };
        saveModelConfig(params).then((res) => {
          if (util.isEmt.format(res)) {
            this.otherTrainingLoading = false;
            return;
          }
          if (res.data.success) {
            this.otherTrainingLoading = false;
            this.$message({
              message: res.data.message,
              type: 'success'
            });
            // this.getDataPermissions();
          }
          else {
            this.otherTrainingLoading = false;
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          }
        });
      });
    },
    saveScriptConfig() {
      this.$confirm('确认应用吗？', '提示', {}).then(() => {
        this.scriptLoading = true;
        let params = {
          contentType: 3,
          baseBottomContent: this.scriptFrom.baseBottomContent,
          configType: 4,
          configId: this.scriptFrom.configId
        };
        saveModelConfig(params).then((res) => {
          if (util.isEmt.format(res)) {
            this.scriptLoading = false;
            return;
          }
          if (res.data.success) {
            this.scriptLoading = false;
            this.$message({
              message: res.data.message,
              type: 'success'
            });
            // this.getDataPermissions();
          }
          else {
            this.scriptLoading = false;
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          }
        });
      });
    },

    getModelConfigInfoFunction() {
      getModelConfigInfo().then((res) => {
        if (res.data.success) {
          var modelConfigList = res.data.response;
          var operatorOne = modelConfigList.find(function (e) {
            return e.configType == 1 && e.contentType == 1;
          });
          if (operatorOne != null) {
            this.operatorFrom = operatorOne;
            this.operatorFrom.temperature = operatorOne.temperature * 10;
            this.operatorFrom.scoreThreshold = operatorOne.scoreThreshold * 100;
          }
          var fireTrainingOne = modelConfigList.find(function (e) {
            return e.configType == 2 && e.contentType == 1;
          });
          if (fireTrainingOne != null) {
            this.fireTrainingFrom = fireTrainingOne;
            this.fireTrainingFrom.temperature = fireTrainingOne.temperature * 10;
            this.fireTrainingFrom.scoreThreshold = fireTrainingOne.scoreThreshold * 100;
          }
          //专业知识
          var operatorSpecialityOne = modelConfigList.find(function (e) {
            return e.configType == 1 && e.contentType == 2;
          });
          if (operatorSpecialityOne != null) {
            this.operatorSpecialityFrom = operatorSpecialityOne;
            this.operatorSpecialityFrom.temperature = operatorSpecialityOne.temperature * 10;
            this.operatorSpecialityFrom.scoreThreshold = operatorSpecialityOne.scoreThreshold * 100;
          }
          var fireTrainingSpecialityOne = modelConfigList.find(function (e) {
            return e.configType == 2 && e.contentType == 2;
          });
          if (fireTrainingSpecialityOne != null) {
            this.fireTrainingSpecialityFrom = fireTrainingSpecialityOne;
            this.fireTrainingSpecialityFrom.temperature = fireTrainingSpecialityOne.temperature * 10;
            this.fireTrainingSpecialityFrom.scoreThreshold = fireTrainingSpecialityOne.scoreThreshold * 100;
          }
          // 其他
          var otherTrain = modelConfigList.find(function (e) {
            return e.contentType == 3;
          });
          if (otherTrain != null) {
            this.otherFrom.isOtherTrainModel = otherTrain.isOtherTrainModel;
          }
          
          // 兜底话术
          var scriptTrain = modelConfigList.find(function (e) {
            return e.contentType == 3 && e.configType === 4;
          });
          if (scriptTrain != null) {
            this.scriptFrom.baseBottomContent = scriptTrain.baseBottomContent;
            this.scriptFrom.configId = scriptTrain.configId;
          }
        }
      });
    },
    formatTooltip1(val) {
      return val / 10;
    },
    formatTooltip(val) {
      return val / 100;
    }
  },
  mounted() {
    this.getModelConfigInfoFunction();
  }
};
</script>
<style lang="stylus" scoped>

.slider {
  margin-left: 10px;
  width:calc(100% - 50px)
}

.line{
  display: flex;
  align-items:center
}

.text{
  margin-left:10px;
  width:20px;
}

::v-deep .el-tabs--border-card{
  border: 1px solid rgb(64, 158, 255);
}

::v-deep .el-tabs--border-card>.el-tabs__header{
  background-color: #FFF;
}
::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item{
  border: 1px solid #409eff;
}


::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
  background-color:rgb(64, 158, 255)
  color: #FFF;
}


</style>
